//
// Taken from github.com/carbon-design-system/carbon/packages/react/src/internal/keyboard/navigation.js
//

// add all the elements inside modal which you want to make focusable
const selectorTabbable = `
  a[href], area[href], input:not([disabled]):not([tabindex='-1']):not([type='hidden']),
  button:not([disabled]):not([tabindex='-1']):not(.invisible),select:not([disabled]):not([tabindex='-1']),
  textarea:not([disabled]):not([tabindex='-1']),
  iframe, object, embed, *[tabindex]:not([tabindex='-1']):not([disabled]), *[contenteditable=true]
`;

export default function focusTrap(node: HTMLElement) {
    node.focus();
    /** @type {(e:KeyboardEvent)=>void} */
    function handleFocusTrap(e: KeyboardEvent) {
        const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

        if (!isTabPressed) {
            return;
        }

        const tabbables: HTMLElement[] = Array.from(node.querySelectorAll(selectorTabbable));

        const firstTabbable = tabbables[0];
        const lastTabbable = tabbables[tabbables.length - 1];

        if (e.shiftKey) {
            if (document.activeElement === firstTabbable) {
                lastTabbable.focus();
                e.preventDefault();
            }
        } else {
            if (document.activeElement === lastTabbable) {
                firstTabbable.focus();
                e.preventDefault();
            }
        }
    }

    node.addEventListener('keydown', handleFocusTrap, true);

    return {
        destroy() {
            node.removeEventListener('keydown', handleFocusTrap, true);
        },
    };
}
